import { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import GoogleSignInButton from "../common/buttons/GoogleSignInButton";
import DividerWithText from "../common/divider/DividerWithText";
import {
  signInWithGoogle,
  signUpWithEmailAndPassword,
} from "../../store/actions/authActions";
import logo from "../../logo.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  box: {
    margin: "10px auto",
    padding: "0px 50px, 20px 50px",
    display: "flex",
    justifycontent: "center",
    flexDirection: "column",
    maxWidth: "650px",
    minWidth: "200px",
  },
  paper: {
    padding: "20px",
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginBottom: "20px",
    justifycontent: "center",
  },
  logoImg: {
    height: "50px",
    width: "250px",
  },
  textField: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginBottom: "10px",
    justifycontent: "center",
  },
  button: {
    textTransform: "none",
    marginBottom: "10px",
    height: "50px",
  },
}));

function SignUp() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  function handleNameFieldChange(e) {
    setName(e.target.value);
  }

  function handleEmailFieldChange(e) {
    setEmail(e.target.value);
  }

  function handlePasswordFieldChange(e) {
    setPassword(e.target.value);
  }

  function handleConfirmPasswordFieldChange(e) {
    setConfirmPassword(e.target.value);
  }

  function createUserWithEmailAndPassword() {
    dispatch(
      signUpWithEmailAndPassword({
        name: name,
        email: email,
        password: password,
        confirmPassword: confirmPassword,
      })
    );
  }

  useEffect(() => {
    if (auth.uid && auth.displayName !== "") {
      history.replace("/dashboard");
    }
  }, [auth.uid, auth.displayName, history]);

  return (
    <div className={classes.root}>
      <Box className={classes.box}>
        <Paper variant="outlined" className={classes.paper}>
          <Grid
            container
            direction="column"
            justifycontent="center"
            alignItems="stretch"
          >
            <Grid className={classes.gridItem} item>
              <img src={logo} className={classes.logoImg} />
            </Grid>
            <Grid className={classes.gridItem} item>
              <Typography variant="h4" component="h4">
                Team SignUp
              </Typography>
              <Typography variant="h6" component="h6">
                Sign Up with an authorized account
              </Typography>
            </Grid>
            <Grid className={classes.gridItem} item xs={12}>
              <GoogleSignInButton
                disabled={false}
                onClick={() => dispatch(signInWithGoogle())}
              />
            </Grid>
            <Grid item xs={12}>
              <DividerWithText>
                Or create an account with your email instead?
              </DividerWithText>
            </Grid>
            <Grid className={classes.textField} item xs={12}>
              <TextField
                fullWidth
                label="Name"
                placeholder="You like to be called as...?"
                variant="outlined"
                onChange={handleNameFieldChange}
              />
            </Grid>
            <Grid className={classes.textField} item xs={12}>
              <TextField
                fullWidth
                label="Email"
                placeholder="Email"
                variant="outlined"
                onChange={handleEmailFieldChange}
              />
            </Grid>
            <Grid className={classes.textField} item xs={12}>
              <TextField
                fullWidth
                label="Password"
                type="password"
                placeholder="Password"
                variant="outlined"
                onChange={handlePasswordFieldChange}
              />
            </Grid>
            <Grid className={classes.textField} item xs={12}>
              <TextField
                fullWidth
                label="Confirm Password"
                type="password"
                placeholder="Re-enter Password"
                variant="outlined"
                onChange={handleConfirmPasswordFieldChange}
              />
            </Grid>
            <Grid className={classes.gridItem} item xs={12}>
              <Button
                fullWidth
                color="primary"
                className={classes.button}
                disabled={false}
                disableElevation={true}
                variant="contained"
                onClick={createUserWithEmailAndPassword}
              >
                Create an Account with Email
              </Button>
            </Grid>
            <Grid item xs={12}>
              <DividerWithText>
                Already have an account? Sign In <Link to="/login">here</Link>
              </DividerWithText>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </div>
  );
}

export default SignUp;
