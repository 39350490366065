import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import {
  makeStyles,
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import { useSelector, useDispatch } from "react-redux";
import { StatusIcon } from "../../common/status-icon/StatusIcon";
import { Status } from "../../common/constants/status";
import ClientList from "./ClientList";
import {
  deleteClient,
  getClientsList,
} from "../../../store/actions/clientActions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  box: {
    margin: "10px auto",
    padding: "0px 50px 20px 50px",
    display: "flex",
    justifycontent: "center",
    flexDirection: "column",
    maxWidth: "90%",
    minWidth: 230,
  },
  paper: {
    padding: "20px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      minWidth: 32,
      paddingLeft: 8,
      paddingRight: 8,
      "& .MuiButton-startIcon": {
        margin: 0,
      },
    },
  },
  buttonDelete: {
    color: theme.palette.error.main,
  },
  buttonText: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  dataGrid: {
    height: 300,
    width: "100%",
    marginTop: 50,
  },
}));

const rows = [
  {
    id: 1,
    first_name: "Kumar Utsav",
    last_name: "Anand",
    email: "kumarutsavanand2@gmail.com",
    status: Status.ElectricianAssigned,
  },
  {
    id: 2,
    first_name: "Kumar Utsav",
    last_name: "Anand",
    email: "kumarutsavanand2@gmail.com",
    status: Status.QuestionnaireSent,
  },
  {
    id: 3,
    first_name: "Kumar Utsav",
    last_name: "Anand",
    email: "kumarutsavanand2@gmail.com",
    status: Status.InstallationDoneSuccessfully,
  },
];

const columns = [
  { field: "client_number", headerName: "Client Number", width: 200 },
  { field: "first_name", headerName: "First Name", width: 170 },
  { field: "last_name", headerName: "Last Name", width: 150 },
  { field: "email", headerName: "Email", width: 250 },
  {
    field: "client_status",
    headerName: "Status",
    width: 300,
    renderCell: (params) => {
      if (params.value) {
        return <StatusIcon status={params.value} />;
      } else {
        return <React.Fragment />;
      }
    },
  },
];

function Header({ selectedClientUID }) {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const dispatch = useDispatch();

  function handleOnClickCreate() {
    history.push("/create-client");
  }

  function handleOnClickEdit() {
    history.push("/edit-client", { UID: selectedClientUID[0] });
  }

  function handleOnClickRefresh() {
    dispatch(getClientsList());
  }

  function handleOnClickDelete(listOfUID) {
    dispatch(deleteClient(listOfUID));
  }

  return (
    <React.Fragment>
      <Grid container className={classes.header} direction="row">
        <Grid item>
          <Typography variant="h6" component="h6">
            Clients
          </Typography>
        </Grid>
        <Grid item>
          <Tooltip title={smDown ? "Create Client" : ""}>
            <Button
              className={classes.button}
              color="primary"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleOnClickCreate}
            >
              <span className={classes.buttonText}>Create</span>
            </Button>
          </Tooltip>
          <Tooltip title={smDown ? "Edit Client" : ""}>
            {/* wrap Button inside of Fragment to get rid of warning */}
            <React.Fragment>
              <Button
                className={classes.button}
                color="primary"
                variant="outlined"
                disabled={selectedClientUID.length !== 1}
                onClick={() => handleOnClickEdit(selectedClientUID[0])}
                startIcon={<EditIcon />}
              >
                <span className={classes.buttonText}>Edit</span>
              </Button>
            </React.Fragment>
          </Tooltip>
          <Tooltip title={smDown ? "Refresh List" : ""}>
            <Button
              className={classes.button}
              color="primary"
              variant="outlined"
              onClick={() => handleOnClickRefresh()}
              startIcon={<RefreshIcon />}
            >
              <span className={classes.buttonText}>Refresh</span>
            </Button>
          </Tooltip>
          <Tooltip title={smDown ? "Delete" : ""}>
            <React.Fragment>
              <Button
                className={classNames(classes.buttonDelete, classes.button)}
                variant="outlined"
                disabled={selectedClientUID.length === 0}
                onClick={() => handleOnClickDelete(selectedClientUID)}
                startIcon={<DeleteIcon />}
              >
                <span className={classes.buttonText}>Delete</span>
              </Button>
            </React.Fragment>
          </Tooltip>
          <Tooltip title={smDown ? "Send Questionare Email" : ""}>
            <Button
              className={classes.button}
              color="primary"
              variant="outlined"
              disabled={selectedClientUID.length === 0}
              startIcon={<SendIcon />}
            >
              <span className={classes.buttonText}>
                Send Questionnaire Email
              </span>
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function Clients() {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const clientsList = useSelector((state) => state.client.clientList);
  const dispatch = useDispatch();
  const [selectedClients, setSelectedClients] = useState([]);

  useEffect(() => {
    dispatch(getClientsList());
  }, []);

  return (
    <div className={classes.root}>
      <Box className={classes.box}>
        <Paper className={classes.paper}>
          <Grid container direction="column">
            <Header selectedClientUID={selectedClients} />
            <ClientList
              rows={clientsList ? clientsList : []}
              columns={columns}
              setSelectedClients={setSelectedClients}
            />
          </Grid>
        </Paper>
      </Box>
    </div>
  );
}

export default Clients;
