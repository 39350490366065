import React from "react";
import {
  makeStyles,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTextField-root": {
      [theme.breakpoints.up("sm")]: {
        width: "100%",
      },
    },
  },
  formContainer: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  questionItems: {
    marginTop: theme.spacing(3),
  },
}));

export default function AddQuestion({
  questionText,
  handleQuestionTextChange,
  answerType,
  handleAnswerTypeChange,
  handleOnClickAdd,
}) {
  const classes = useStyles();
  return (
    <Grid
      className={classNames(classes.form)}
      container
      spacing={1}
      direction="row"
    >
      <Grid className={classes.questionItems} item xs={9}>
        <TextField
          required
          value={questionText}
          variant="outlined"
          label="Question ?"
          onChange={handleQuestionTextChange}
        />
      </Grid>
      <Grid item className={classes.questionItems}>
        <FormControl
          required
          variant="outlined"
          className={classNames(classes.formControl)}
        >
          <InputLabel shrink id="answer-type-select-label">
            Answer Type
          </InputLabel>
          <Select
            labelId="answer-type-select-label"
            id="answer-type-select"
            displayEmpty
            value={answerType}
            onChange={handleAnswerTypeChange}
            className={classes.selectEmpty}
          >
            <MenuItem value="Text">Text</MenuItem>
            <MenuItem value="Picture">Picture</MenuItem>
            <MenuItem value="Video">Video</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid className={classes.questionItems} item>
        <Button variant="outlined" onClick={handleOnClickAdd}>
          Add
        </Button>
      </Grid>
    </Grid>
  );
}
