const initState = {
  questionnaireID: null,
  questionnaireList: [],
  questionnaire: null,
  questionnaireSubmitted: false,
  error: null,
};

const questionnaireReducer = (state = initState, action) => {
  switch (action.type) {
    case "ADD_QUESTIONNAIRE_SUCCESS":
      return {
        ...state,
        questionnaireID: action.payload,
        questionnaireSubmitted: true,
        error: null,
      };
    case "ADD_QUESTIONNAIRE_FAILED":
      return {
        ...state,
        questionnaireID: null,
        error: action.payload,
      };
    case "QUESTIONNAIRE_SUBMITTED_VAL_RESET":
      return {
        ...state,
        questionnaireSubmitted: action.payload,
      };
    case "LIST_QUESTIONNAIRE_SUCCESS":
      return {
        ...state,
        questionnaireList: action.payload,
        error: null,
      };
    case "LIST_QUESTIONNAIRE_FAILED":
      return {
        ...state,
        questionnaireList: [],
        error: action.payload,
      };
    case "GET_QUESTIONNAIRE_SUCCESS":
      return {
        ...state,
        questionnaire: action.payload,
        error: null,
      };
    case "GET_QUESTIONNAIRE_FAILED":
      return {
        ...state,
        questionnaire: null,
        error: action.payload,
      };
    case "DELETE_QUESTIONNAIRE_SUCCESS":
      return {
        ...state,
        questionnaireList: action.payload,
        error: null,
      };
    case "DELETE_QUESTIONNAIRE_FAILED":
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default questionnaireReducer;
