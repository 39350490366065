import { Button, makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { signOut } from "../../../store/actions/authActions";

const useStyles = makeStyles((theme) => ({
  menuButtonText: {
    textTransform: "none",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

function LogoutButton() {
  const dispatch = useDispatch();
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Button
        disableElevation={true}
        color="primary"
        size="small"
        onClick={() => {
          dispatch(signOut());
        }}
        className={classes.menuButtonText}
      >
        Logout
      </Button>
    </div>
  );
}

export default LogoutButton;
