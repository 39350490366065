import React from "react";
import { isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { CircularProgress, Backdrop } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "transparent",
  },
  circularProgress: {
    color: "#298D74",
  },
}));

function AuthIsLoaded({ children }) {
  const classes = useStyles();
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth))
    return (
      <React.Fragment>
        <Backdrop open={true} className={classes.backdrop}>
          <CircularProgress className={classes.circularProgress} />
        </Backdrop>
      </React.Fragment>
    );
  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthIsLoaded;
