const initState = {
  createClientErr: null,
  createClientUIDRes: null,
  clientCreated: false,
  clientList: [],
  client: null,
  error: null,
};

const clientReducer = (state = initState, action) => {
  switch (action.type) {
    case "CLIENT_CREATE_SUCCESS":
      return {
        ...state,
        error: null,
        createClientUIDRes: action.payload,
        clientCreated: true,
      };
    case "CLIENT_CREATE_FAILED":
      return {
        ...state,
        error: action.payload,
        createClientUIDRes: null,
        clientCreated: false,
      };
    case "CLIENT_CREATED_VAL_RESET":
      return {
        ...state,
        clientCreated: action.payload,
      };
    case "CLIENT_LIST_SUCCESS":
      return {
        ...state,
        clientList: action.payload,
        clientListErr: null,
      };
    case "CLIENT_LIST_FAILED":
      return {
        ...state,
        clientList: null,
        error: action.payload,
      };
    case "GET_CLIENT_SUCCESS":
      return {
        ...state,
        client: action.payload,
        error: null,
      };
    case "GET_CLIENT_FAILED":
      return {
        ...state,
        client: null,
        error: action.payload,
      };
    case "DELETE_CLIENT_SUCCESS":
      return {
        ...state,
        clientList: action.payload,
        error: null,
      };
    case "DELETE_CLIENT_FAILED":
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default clientReducer;
