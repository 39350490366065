export const Status = {
  UserSetupinProgress: "User Setup in Progress",
  QuestionnaireSent: "Questionnaire Sent",
  QuestionnairePartiallyFilled: "Questionnaire Partially Filled",
  QuestionnaireSubmitted: "Questionnaire Submitted",
  ElectricianAssigned: "Electrician Assigned",
  QuestionnaireChecked: "Questionnaire Checked",
  InstallationAppointmentSent: "Installation Appointment Sent",
  MaterialOrdered: "Material Ordered",
  InstallationScheduled: "Installation Scheduled",
  InstallationDoneSuccessfully: "Installation Done Successfully",
  InstallationOpenTasks: "Installation OpenTasks",
};
