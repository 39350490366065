import React from "react";
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import PrivateRoute from "./components/common/private-route/PrivateRoute";
import AuthIsLoaded from "./components/common/auth_is_loaded/AuthIsLoaded";
import NavBar from "./components/common/navigation/NavBar";
import Login from "./components/logged_out/Login";
import SignUp from "./components/logged_out/SignUp";
import Dashboard from "./components/logged_in/dashboard/Dashboard";
import Clients from "./components/logged_in/clients/Clients";
import CreateClient from "./components/logged_in/clients/CreateClient";
import Questionnaire from "./components/logged_in/questionnaire/Questionnaire";
import Technician from "./components/logged_in/technician/Technician";
import Team from "./components/logged_in/team/Team";
import CreateQuestionnaire from "./components/logged_in/questionnaire/CreateQuestionnaire";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2B8D75",
    },
    secondary: {
      main: "#004358",
    },
  },
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <AuthIsLoaded>
        <React.Fragment>
          <Router>
            <NavBar />
            <Switch>
              <Route exact path="/" component={Login} />
              <Route path="/login" component={Login} />
              <Route path="/logout">
                <Redirect to="/login" />
              </Route>
              <Route path="/signup" component={SignUp} />
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/clients" component={Clients} />
              <PrivateRoute
                exact
                path="/create-client"
                component={CreateClient}
              />
              <PrivateRoute
                exact
                path="/edit-client"
                component={CreateClient}
              />
              <PrivateRoute
                exact
                path="/questionnaire"
                component={Questionnaire}
              />
              <PrivateRoute
                exact
                path="/create-questionnaire"
                component={CreateQuestionnaire}
              />
              <PrivateRoute
                exact
                path="/edit-questionnaire/:questionnaireIDParam"
                component={CreateQuestionnaire}
              />
              <PrivateRoute exact path="/technician" component={Technician} />
              <PrivateRoute exact path="/team" component={Team} />
            </Switch>
          </Router>
        </React.Fragment>
      </AuthIsLoaded>
    </MuiThemeProvider>
  );
}

export default App;
