import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  makeStyles,
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Tooltip,
  TextField,
  useMediaQuery,
  useTheme,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import { useSelector, useDispatch } from "react-redux";
import {
  addNewClient,
  getClient,
  resetClientCreatedVal,
} from "../../../store/actions/clientActions";
import { Status } from "../../common/constants/status";
import { listQuestionnaires } from "../../../store/actions/questionnaireActions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  box: {
    margin: "10px auto",
    padding: "0px 50px 20px 50px",
    display: "flex",
    justifycontent: "center",
    flexDirection: "column",
    maxWidth: "90%",
    minWidth: 230,
  },
  paper: {
    padding: "20px",
  },
  button: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      minWidth: 32,
      paddingLeft: 8,
      paddingRight: 8,
      "& .MuiButton-startIcon": {
        margin: 0,
      },
    },
  },
  buttonText: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  header: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      [theme.breakpoints.up("sm")]: {
        width: "30ch",
      },
    },
  },
  formContainer: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(3),
  },
}));

function CreateClientButtons({
  editMode,
  handleOnClickSave,
  handleOnClickCreateUser,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <React.Fragment>
      <Grid container className={classes.footer} direction="row">
        <Grid item>
          <Tooltip title={smDown ? "Save Client to Draft" : ""}>
            <Button
              className={classes.button}
              color="primary"
              variant="outlined"
              disabled
              startIcon={<SaveIcon />}
              onClick={handleOnClickSave}
            >
              <span className={classes.buttonText}>Save Client to Draft</span>
            </Button>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title={smDown ? "Create User" : ""}>
            <Button
              className={classes.button}
              color="primary"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleOnClickCreateUser}
            >
              <span className={classes.buttonText}>
                {editMode ? "Save Changes" : "Create User"}
              </span>
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function CreateClient() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [street, setStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [email, setEmail] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [clientNumber, setClientNumber] = useState("");
  const [orderType, setOrderType] = useState("Installation Service");
  const [wallBoxPower, setWallBoxPower] = useState("11");
  const client = useSelector((state) => state.client.client);
  const questionnaires = useSelector(
    (state) => state.questionnaire.questionnaireList
  );
  const [questionnaireID, setQuestionnaire] = useState("");
  const clientCreated = useSelector((state) => state.client.clientCreated);
  const error = useSelector((state) => state.client.error);

  const [editMode, setEditMode] = useState(false);

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };

  const handleStreetChange = (event) => {
    setStreet(event.target.value);
  };

  const handleHouseNumberChange = (event) => {
    setHouseNumber(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleOrderNumberChange = (event) => {
    setOrderNumber(event.target.value);
  };

  const handleClientNumberChange = (event) => {
    setClientNumber(event.target.value);
  };

  const handleOrderTypeChange = (event) => {
    setOrderType(event.target.value);
  };

  const handleWallBoxPowerChange = (event) => {
    setWallBoxPower(event.target.value);
  };

  const handleQuestionnaireChange = (event) => {
    console.log(event.target.value);
    setQuestionnaire(event.target.value);
  };

  const handleOnClickCreateUser = () => {
    var client = {
      first_name: firstName,
      last_name: lastName,
      company_name: companyName,
      street: street,
      house_number: houseNumber,
      email: email,
      order_number: orderNumber,
      client_number: clientNumber,
      order_spec: orderType,
      wallbox_type: wallBoxPower,
      questionnaire_id: questionnaireID,
      client_status: Status.UserSetupinProgress,
    };
    dispatch(addNewClient(client));
  };

  // runs on first render to get all the questionnaires from the datastore
  useEffect(() => {
    dispatch(listQuestionnaires());
  }, []);

  // runs when the client creation is successful
  // and makes the app return to the clients page
  useEffect(() => {
    if (clientCreated === true && error === null) {
      dispatch(resetClientCreatedVal());
      history.push("/clients");
    }
  }, [clientCreated]);

  useEffect(() => {
    const state = location.state || null;
    if (state !== null && client === null) {
      setEditMode(true);
      dispatch(getClient(location.state.UID));
    } else if (state !== null && client !== null) {
      setEditMode(true);
      setFirstName(client.first_name);
      setLastName(client.last_name);
      setCompanyName(client.company_name);
      setStreet(client.street);
      setHouseNumber(client.house_number);
      setEmail(client.email);
      setOrderNumber(client.order_number);
      setClientNumber(client.client_number);
      // setOrderType(client.order_spec);
      // setWallBoxPower(client.wallbox_type);
      setQuestionnaire(client.questionnaire_id);
      console.log(client);
    } else if (!editMode && questionnaires.length != 0) {
      // run to set the questionnaire to a default after the store has been
      // populated with existing questionnaires when not opened in edit mode
      setQuestionnaire(questionnaires[0].questionnaire_id);
    }
  }, [client, questionnaires]);

  return (
    <div className={classes.root}>
      <Box className={classes.box}>
        <Paper className={classes.paper}>
          <Grid container direction="column">
            <Grid className={classes.header} item>
              <Typography variant="h6" component="h6">
                {editMode ? "Edit Client" : "Create Client"}
              </Typography>
            </Grid>
            <div className={classes.formContainer}>
              <Grid className={classes.form} item xs={12}>
                <TextField
                  required
                  variant="outlined"
                  label="First Name"
                  value={firstName}
                  onChange={handleFirstNameChange}
                />
                <TextField
                  required
                  variant="outlined"
                  label="Last Name"
                  value={lastName}
                  onChange={handleLastNameChange}
                />
              </Grid>
              <Grid className={classes.form} item xs={12}>
                <TextField
                  variant="outlined"
                  label="Company Name"
                  value={companyName}
                  onChange={handleCompanyNameChange}
                />
              </Grid>
              <Grid className={classes.form} item>
                <TextField
                  required
                  variant="outlined"
                  label="Street"
                  value={street}
                  onChange={handleStreetChange}
                />
                <TextField
                  required
                  variant="outlined"
                  label="House Number"
                  value={houseNumber}
                  onChange={handleHouseNumberChange}
                />
              </Grid>
              <Grid className={classes.form} item xs={12}>
                <TextField
                  required
                  variant="outlined"
                  label="Email"
                  value={email}
                  onChange={handleEmailChange}
                />
              </Grid>
              <Grid className={classes.form} item xs={12}>
                <TextField
                  required
                  variant="outlined"
                  label="Order Number"
                  value={orderNumber}
                  onChange={handleOrderNumberChange}
                />
                <TextField
                  required
                  variant="outlined"
                  label="Client Number"
                  value={clientNumber}
                  onChange={handleClientNumberChange}
                />
              </Grid>
              <Grid className={classes.form} item xs={6}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel shrink id="order-spec-select-label">
                    Order Spec
                  </InputLabel>
                  <Select
                    labelId="order-spec-select-label"
                    id="order-spec-select"
                    displayEmpty
                    value={orderType}
                    onChange={handleOrderTypeChange}
                    className={classes.selectEmpty}
                  >
                    <MenuItem value="Consulting Service">
                      Consulting Service
                    </MenuItem>
                    <MenuItem value="Hardware">Hardware</MenuItem>
                    <MenuItem value="Installation Service">
                      Installation Service
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid className={classes.form} item xs={12}>
                <FormControl
                  className={classes.formControl}
                  component="fieldset"
                >
                  <FormLabel component="legend">WallBox Type</FormLabel>
                  <RadioGroup
                    aria-label="wallbox-type"
                    name="wallbox-type"
                    value={wallBoxPower}
                    onChange={handleWallBoxPowerChange}
                  >
                    <FormControlLabel
                      value="11"
                      control={<Radio />}
                      label="11 kW"
                    />
                    <FormControlLabel
                      value="22"
                      control={<Radio />}
                      label="22 kW"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid className={classes.form} item xs={6}>
                <FormControl
                  required
                  variant="outlined"
                  disabled={
                    questionnaires === null || questionnaires.length === 0
                  }
                  className={classes.formControl}
                >
                  <InputLabel shrink id="questionnaire-select-label">
                    Questionare Type
                  </InputLabel>
                  <Select
                    labelId="questionnaire-select-label"
                    id="questionnaire-select"
                    displayEmpty
                    value={questionnaireID}
                    onChange={handleQuestionnaireChange}
                    disabled={
                      questionnaires === null || questionnaires.length === 0
                    }
                    className={classes.selectEmpty}
                  >
                    {questionnaires !== null
                      ? questionnaires.map((q) => (
                          <MenuItem
                            key={q.questionnaire_id}
                            value={q.questionnaire_id}
                          >
                            {q.title}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
            </div>
            <CreateClientButtons
              editMode={editMode}
              handleOnClickCreateUser={handleOnClickCreateUser}
            />
          </Grid>
        </Paper>
      </Box>
    </div>
  );
}

export default CreateClient;
