import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  makeStyles,
  Box,
  Paper,
  useTheme,
  useMediaQuery,
  Grid,
  Tooltip,
  Button,
  Typography,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  deleteQuestionnaire,
  listQuestionnaires,
} from "../../../store/actions/questionnaireActions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  box: {
    margin: "10px auto",
    padding: "0px 50px 20px 50px",
    display: "flex",
    justifycontent: "center",
    flexDirection: "column",
    maxWidth: "90%",
    minWidth: 230,
  },
  paper: {
    padding: "20px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      minWidth: 32,
      paddingLeft: 8,
      paddingRight: 8,
      "& .MuiButton-startIcon": {
        margin: 0,
      },
    },
  },
  buttonText: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  dataGrid: {
    height: 300,
    width: "100%",
    marginTop: 50,
  },
}));

const columns = [
  { field: "questionnaire_title", headerName: "Questionnaire", width: 270 },
];

function Header({ handleOnClickDelete }) {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  function handleOnClickCreate() {
    history.push("/create-questionnaire");
  }

  return (
    <React.Fragment>
      <Grid container className={classes.header} direction="row">
        <Grid item>
          <Typography variant="h6" component="h6">
            Questionnaire
          </Typography>
        </Grid>
        <Grid item>
          <Tooltip title={smDown ? "Create Questionnaire" : ""}>
            <Button
              className={classes.button}
              color="primary"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleOnClickCreate}
            >
              <span className={classes.buttonText}>Create</span>
            </Button>
          </Tooltip>
          <Tooltip title={smDown ? "Refresh List" : ""}>
            <Button
              className={classes.button}
              color="primary"
              variant="outlined"
              startIcon={<RefreshIcon />}
            >
              <span className={classes.buttonText}>Refresh</span>
            </Button>
          </Tooltip>
          <Tooltip title={smDown ? "Delete" : ""}>
            <Button
              className={classes.button}
              style={{ color: theme.palette.error.main }}
              variant="outlined"
              onClick={handleOnClickDelete}
              startIcon={<DeleteIcon />}
            >
              <span className={classes.buttonText}>Delete</span>
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function Questionnaire() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const questionnaireList = useSelector(
    (state) => state.questionnaire.questionnaireList
  );
  const [select, setSelect] = useState([]);

  const columns = [
    {
      field: "questionnaire_id",
      headerName: "Questionnaire ID",
      width: 170,
      renderCell: (params) => {
        if (params.value) {
          return (
            <Link
              to={{
                pathname: `/edit-questionnaire/${params.value}`,
                state: params.value,
              }}
            >
              {params.value}
            </Link>
          );
        } else {
          return <React.Fragment />;
        }
      },
    },
    { field: "title", headerName: "Questionnaire", width: 270 },
  ];
  const getRowId = (row) => row.questionnaire_id;

  useEffect(() => {
    dispatch(listQuestionnaires());
  }, []);

  function handleOnClickDelete(listOfSelectedIDs) {
    dispatch(deleteQuestionnaire(listOfSelectedIDs));
  }

  return (
    <div className={classes.root}>
      <Box className={classes.box}>
        <Paper className={classes.paper}>
          <Grid container direction="column">
            <Header handleOnClickDelete={() => handleOnClickDelete(select)} />
            <Grid item className={classes.dataGrid}>
              <DataGrid
                getRowId={getRowId}
                rows={questionnaireList ? questionnaireList : []}
                columns={columns}
                checkboxSelection
                onSelectionChange={(newSelection) => {
                  setSelect(newSelection.rowIds);
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </div>
  );
}

export default Questionnaire;
