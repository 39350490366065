import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Grid,
  makeStyles,
  useTheme,
  Drawer,
  IconButton,
  useMediaQuery,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ClickAwayListener,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useSelector } from "react-redux";
import clsx from "clsx";
import LoginSignUpButtons from "../buttons/LoginSignUpButtons";
import LogoutButton from "../buttons/LogoutButton";
import logo from "../../../logo.svg";
import smallLogo from "../../../smallLogo.svg";

import DashboardIcon from "@material-ui/icons/Dashboard";
import ClientIcon from "@material-ui/icons/Face";
import QuestionareIcon from "@material-ui/icons/List";
import TechnicianIcon from "@material-ui/icons/BuildRounded";
import TeamIcon from "@material-ui/icons/PeopleAltRounded";

const drawerWidth = 200;

const menuItems = [
  {
    text: "Dashboard",
    icon: <DashboardIcon />,
    to: "/dashboard",
  },
  {
    text: "Clients",
    icon: <ClientIcon />,
    to: "/clients",
  },
  {
    text: "Questionare",
    icon: <QuestionareIcon />,
    to: "/questionnaire",
  },
  {
    text: "Technician",
    icon: <TechnicianIcon />,
    to: "/technician",
  },
  {
    text: "Team",
    icon: <TeamIcon />,
    to: "/team",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    boxShadow: theme.shadows[0],
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    height: "70px",
  },
  logoContainer: {
    margin: "5px, 0px",
  },
  logoImg: {
    height: "60px",
    width: "200px",
  },
  smallLogo: {
    height: "60px",
  },
  logoGridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  menuContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    background: theme.palette.primary,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

function DahemLadenLogo({ auth, xsDown }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      {auth.uid ? (
        <React.Fragment>
          {auth.uid ? (
            <React.Fragment>
              {xsDown ? (
                <img src={smallLogo} className={classes.smallLogo} />
              ) : (
                <img src={logo} className={classes.logoImg} />
              )}
            </React.Fragment>
          ) : (
            <React.Fragment />
          )}
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
}

function NavBar() {
  const classes = useStyles();
  const history = useHistory();
  const auth = useSelector((state) => state.firebase.auth);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const goTo = (path) => {
    history.push(path);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Grid className={classes.toolbar} container>
            <Grid xs={6} item>
              <div className={classes.menuContainer}>
                {auth.uid ? (
                  <IconButton
                    className={clsx(classes.menuButton, open && classes.hide)}
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerOpen}
                  >
                    <MenuIcon color="primary" />
                  </IconButton>
                ) : null}
                <DahemLadenLogo auth={auth} xsDown={xsDown} />
              </div>
            </Grid>
            <Grid xs={6} item>
              {auth.uid ? <LogoutButton /> : <LoginSignUpButtons />}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {auth.uid ? (
        <Drawer
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="temporary"
          open={open}
          anchor="left"
          onBackdropClick={handleDrawerClose}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            {menuItems.map((e) => (
              <div
                key={e.to}
                onClick={() => {
                  handleDrawerClose();
                  goTo(e.to);
                }}
              >
                <ListItem button key={e.text}>
                  <ListItemIcon>{e.icon}</ListItemIcon>
                  <ListItemText>{e.text}</ListItemText>
                </ListItem>
              </div>
            ))}
          </List>
        </Drawer>
      ) : null}
    </div>
  );
}

export default NavBar;
