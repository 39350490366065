import { makeStyles, Box, Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  box: {
    margin: "10px auto",
    padding: "0px 50px 20px 50px",
    display: "flex",
    justifycontent: "center",
    flexDirection: "column",
    maxWidth: "90%",
    minWidth: 230,
  },
  paper: {
    padding: "20px",
  },
}));

function Team() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box className={classes.box}>
        <Paper className={classes.paper}>
          <Typography variant="h6" component="h6">
            Team
          </Typography>
        </Paper>
      </Box>
    </div>
  );
}

export default Team;
