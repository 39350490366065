import { Link } from "react-router-dom";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  menuButtonText: {
    textTransform: "none",
  },
  linksContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  links: {
    margin: "0px 5px",
    textDecoration: "none",
  },
}));

function LoginSignUpButtons() {
  const classes = useStyles();
  const menuItems = [
    {
      link: "/login",
      name: "Login",
    },
    {
      link: "/signup",
      name: "Sign Up",
    },
  ];

  return (
    <div className={classes.linksContainer}>
      {menuItems.map((element) => {
        if (element.name === "Login") {
          return (
            <Link
              key={element.link}
              to={element.link}
              className={classes.links}
            >
              <Button
                disableElevation={false}
                color="primary"
                size="small"
                className={classes.menuButtonText}
              >
                {element.name}
              </Button>
            </Link>
          );
        }
        return (
          <Link key={element.link} to={element.link} className={classes.links}>
            <Button
              disableElevation={true}
              variant="contained"
              color="primary"
              size="small"
              className={classes.menuButtonText}
            >
              {element.name}
            </Button>
          </Link>
        );
      })}
    </div>
  );
}

export default LoginSignUpButtons;
