const initState = {
  admin: null,
  idToken: null,
  authError: null,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "SIGNIN_ERROR":
      return {
        ...state,
        authError: action.payload,
      };
    case "SIGNIN_SUCCESS":
      return {
        ...state,
        authError: null,
      };
    case "SIGNOUT_SUCCESS":
      return state;
    case "ID_TOKEN_RETRIEVED":
      return {
        ...state,
        idToken: action.payload,
      };
    case "ACC_TYPE_ADMIN":
      return {
        ...state,
        admin: true,
      };
    case "ACC_TYPE_NOT_ADMIN":
      return {
        ...state,
        admin: false,
      };
    default:
      return state;
  }
};

export default authReducer;
