import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Tooltip,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useDispatch, useSelector } from "react-redux";
import {
  saveQuestionnaire,
  resetQuestionnaireSubmittedVal,
  getQuestionnaire,
} from "../../../store/actions/questionnaireActions";
import AddQuestion from "./AddQuestion";
import Questions from "./Questions";
import { useHistory, useLocation } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
  },
  box: {
    margin: "10px auto",
    padding: "0px 50px 20px 50px",
    display: "flex",
    justifycontent: "center",
    flexDirection: "column",
    maxWidth: "90%",
    minWidth: 230,
  },
  paper: {
    padding: "20px",
  },
  button: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      minWidth: 32,
      paddingLeft: 8,
      paddingRight: 8,
      "& .MuiButton-startIcon": {
        margin: 0,
      },
    },
  },
  buttonText: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  header: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  title: {
    "& .MuiTextField-root": {
      marginTop: theme.spacing(1),
      [theme.breakpoints.up("sm")]: {
        width: "30%",
      },
    },
  },
  questionsTable: {
    marginTop: theme.spacing(1),
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: theme.spacing(3),
  },
}));

function CreateQuestionnaireButtons({ editMode, handleOnClickSave }) {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <React.Fragment>
      <Grid container className={classes.footer} direction="row">
        <Grid item>
          <Tooltip title={smDown ? (editMode ? "Save Changes" : "Save") : ""}>
            <Button
              className={classes.button}
              color="primary"
              variant="outlined"
              startIcon={<SaveIcon />}
              onClick={handleOnClickSave}
            >
              <span className={classes.buttonText}>
                {editMode ? "Save Changes" : "Save"}
              </span>
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function CreateQuestionnaire(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const questionnaireSubmitted = useSelector(
    (state) => state.questionnaire.questionnaireSubmitted
  );
  const questionnaire = useSelector(
    (state) => state.questionnaire.questionnaire
  );
  const error = useSelector((state) => state.questionnaire.error);
  const [editMode, setEditMode] = useState(false);
  const [questionnaireTitle, setQuestionnaireTitle] = useState("");
  const [questionText, setQuestionText] = useState("");
  const [answerType, setAnswerType] = useState("Text");
  const [questions, setQuestions] = useState([]);

  const handleQuestionnaireTitleChange = (event) => {
    setQuestionnaireTitle(event.target.value);
  };

  const handleQuestionTextChange = (event) => {
    setQuestionText(event.target.value);
  };

  const handleAnswerTypeChange = (event) => {
    setAnswerType(event.target.value);
  };

  const handleOnClickAdd = () => {
    setQuestions([
      ...questions,
      { question_text: questionText, answer_type: answerType },
    ]);
    setQuestionText("");
    setAnswerType("Text");
  };

  const handleOnClickDelete = (index) => {
    console.log(questions);
    setQuestions(questions.filter((question, i) => i !== index));
    console.log(questions);
  };

  const handleOnClickSaveQuestionnaire = (questionnaireTitle, questions) => {
    dispatch(saveQuestionnaire(questionnaireTitle, questions));
  };

  useEffect(() => {
    if (questionnaireSubmitted === true && error === null) {
      dispatch(resetQuestionnaireSubmittedVal());
      history.push("/questionnaire");
    }
  }, [questionnaireSubmitted]);

  useEffect(() => {
    const state = location.state || null;
    if (state !== null && questionnaire === null) {
      setEditMode(true);
      dispatch(getQuestionnaire(location.state));
    }
    if (state !== null && questionnaire !== null) {
      setEditMode(true);
      setQuestionnaireTitle(questionnaire.title);
      setQuestions(questionnaire.questions);
    }
  }, [questionnaire]);

  return (
    <div className={classes.root}>
      <Box className={classes.box}>
        <Paper className={classes.paper}>
          <Grid container direction="column">
            <Grid className={classes.header} item>
              <Typography variant="h6" component="h6">
                {editMode ? "Edit Questionnaire" : "Create Questionnaire"}
              </Typography>
            </Grid>
            <Grid className={classes.title} item xs={12}>
              <TextField
                required
                variant="outlined"
                label="Title"
                value={questionnaireTitle}
                onChange={handleQuestionnaireTitleChange}
              />
            </Grid>
            <Grid item className={classes.questionsTable}>
              <Questions
                questions={questions}
                handleOnClickDelete={handleOnClickDelete}
              />
            </Grid>
            <AddQuestion
              questionText={questionText}
              answerType={answerType}
              handleQuestionTextChange={handleQuestionTextChange}
              handleAnswerTypeChange={handleAnswerTypeChange}
              handleOnClickAdd={handleOnClickAdd}
            />
            <CreateQuestionnaireButtons
              editMode={editMode}
              handleOnClickSave={() =>
                handleOnClickSaveQuestionnaire(questionnaireTitle, questions)
              }
            />
          </Grid>
        </Paper>
      </Box>
    </div>
  );
}

export default CreateQuestionnaire;
