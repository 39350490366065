export const signInWithEmailAndPassword = (credentials) => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch({ type: "SIGNIN_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "SIGNIN_ERROR", payload: err });
      });
  };
};

export const signInWithGoogle = () => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    var googleProvider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().useDeviceLanguage();
    googleProvider.addScope("profile");
    googleProvider.addScope("email");
    firebase
      .auth()
      .signInWithRedirect(googleProvider)
      .then(() => {
        dispatch({ type: "SIGNIN_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "SIGNIN_ERROR", payload: err });
      });
  };
};

export const signUpWithEmailAndPassword = (credentials) => {
  return (dispatch, getState, getFirebase) => {
    if (credentials.password !== credentials.confirmPassword) {
      return dispatch({
        type: "LOGIN_ERROR",
        payload: "The two passwords do not match!",
      });
    }

    const firebase = getFirebase();
    firebase
      .auth()
      .createUserWithEmailAndPassword(credentials.email, credentials.password)
      .then((result) => {
        result.user
          .updateProfile({
            displayName: credentials.name,
          })
          .then(() => {
            dispatch({ type: "SIGNIN_SUCCESS" });
          })
          .catch((err) => {
            dispatch({ type: "SIGNIN_ERROR", payload: err.message });
          });
      })
      .catch((err) => {
        dispatch({ type: "SIGNIN_ERROR", payload: err.message });
      });
  };
};

export const getIdToken = () => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();

    var user = firebase.auth().currentUser;
    user
      .getIdToken(true)
      .then((idToken) => {
        dispatch({ type: "ID_TOKEN_RETRIEVED", payload: idToken });
      })
      .catch((err) => {
        dispatch({ type: "ID_TOKEN_RETRIEVE_ERROR", payload: err });
      });
  };
};

export const getAccountRole = () => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();

    var user = firebase.auth().currentUser;
    user.getIdTokenResult(true).then((result) => {
      console.log(result.claims);
      if (result.claims.type == "admin") {
        dispatch({ type: "ACC_TYPE_ADMIN" });
      } else {
        dispatch({ type: "ACC_TYPE_NOT_ADMIN" });
      }
    });
  };
};

export const signOut = () => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "SIGNOUT_SUCCESS" });
      });
  };
};
