import { makeStyles, useTheme } from "@material-ui/core";
import SuccessIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "25px",
    borderStyle: "solid",
    borderWidth: 2,
    flexGrow: 0,
    flexShrink: 0,
    height: 35,
  },
  item: {
    marginLeft: 10,
    marginRight: 10,
  },
}));

export function StatusIcon({ status }) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.root} style={{ color: theme.palette.success.main }}>
      <SuccessIcon className={classes.item} />
      <span className={classes.item}>{status}</span>
    </div>
  );
}
