import axios from "axios";
import { getFirebase } from "react-redux-firebase";

const api = axios.create({
  baseURL: "https://daheimladen-rest-apiserver-djomn7cwbq-ey.a.run.app/v1/",
});

export const getClientsList = () => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        api
          .get("/listclients", {
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((res) => {
            console.log(res);
            dispatch({ type: "CLIENT_LIST_SUCCESS", payload: res.data });
          })
          .catch((err) => {
            console.log(err);
            dispatch({ type: "CLIENT_LIST_FAILED", payload: err });
          });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "CLIENT_LIST_FAILED", payload: err.message });
      });
  };
};

export const getClient = (uid) => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        api
          .get(`/client/${uid}`, {
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((res) => {
            console.log(res);
            dispatch({ type: "GET_CLIENT_SUCCESS", payload: res.data });
          })
          .catch((err) => {
            console.log(err);
            dispatch({ type: "GET_CLIENT_FAILED", payload: err });
          });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "GET_CLIENT_FAILED", payload: err.message });
      });
  };
};

export const addNewClient = (client) => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        api
          .post("/client", client, {
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((res) => {
            console.log(res);
            dispatch({ type: "CLIENT_CREATE_SUCCESS", payload: res.data });
          })
          .catch((err) => {
            console.log(err);
            dispatch({ type: "CLIENT_CREATE_FAILED", payload: err });
          });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "CLIENT_CREATE_FAILED", payload: err.message });
      });
  };
};

export const deleteClient = (listOfUID) => {
  return (dispatch, getState, getFirebase) => {
    const { clientList } = getState().client;
    const firebase = getFirebase();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        axios
          .all(
            listOfUID.map((uid) =>
              api.delete(`/client/${uid}`, {
                headers: { Authorization: `Bearer ${idToken}` },
              })
            )
          )
          .then((resArr) => {
            console.log(resArr);
            const reducedList = clientList.filter(
              (client) => !resArr.some((res) => res.data.uid === client.uid)
            );
            dispatch({ type: "DELETE_CLIENT_SUCCESS", payload: reducedList });
          })
          .catch((err) => {
            console.log(err);
            dispatch({ type: "DELETE_CLIENT_FAILED", payload: err });
          });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "DELETE_CLIENT_FAILED", payload: err.message });
      });
  };
};

export const resetClientCreatedVal = () => {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: "CLIENT_CREATED_VAL_RESET", payload: false });
  };
};
