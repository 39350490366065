import axios from "axios";

const api = axios.create({
  baseURL: "https://daheimladen-rest-apiserver-djomn7cwbq-ey.a.run.app/v1/",
});

export const saveQuestionnaire = (questionnaireTitle, questions) => {
  return (dispatch, getState, getFirebase) => {
    const data = {
      title: questionnaireTitle,
      questions: questions,
    };
    const firebase = getFirebase();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        api
          .post("/questionnaire", data, {
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((res) => {
            dispatch({
              type: "ADD_QUESTIONNAIRE_SUCCESS",
              payload: res.data.questionnaire_id,
            });
          })
          .catch((err) => {
            console.log(err);
            dispatch({ type: "ADD_QUESTIONNAIRE_FAILED", payload: err });
          });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "ADD_QUESTIONNAIRE_FAILED", payload: err.message });
      });
  };
};

export const resetQuestionnaireSubmittedVal = () => {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: "QUESTIONNAIRE_SUBMITTED_VAL_RESET", payload: false });
  };
};

export const getQuestionnaire = (id) => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        api
          .get(`/questionnaire/${id}`, {
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((res) => {
            dispatch({ type: "GET_QUESTIONNAIRE_SUCCESS", payload: res.data });
          })
          .catch((err) => {
            dispatch({ type: "GET_QUESTIONNAIRE_FAILED", payload: err });
          });
      })
      .catch((err) => {
        dispatch({ type: "GET_QUESTIONNAIRE_FAILED", payload: err.message });
      });
  };
};

export const listQuestionnaires = () => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        api
          .get("/listquestionnaire", {
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((res) => {
            dispatch({
              type: "LIST_QUESTIONNAIRE_SUCCESS",
              payload: res.data,
            });
          })
          .catch((err) => {
            dispatch({ type: "LIST_QUESTIONNAIRE_FAILED", payload: err });
          });
      })
      .catch((err) => {
        dispatch({ type: "LIST_QUESTIONNAIRE_FAILED", payload: err });
      });
  };
};

export const deleteQuestionnaire = (listOfIDs) => {
  return (dispatch, getState, getFirebase) => {
    const { questionnaireList } = getState().questionnaire;
    const firebase = getFirebase();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        axios
          .all(
            listOfIDs.map((id) =>
              api.delete(`/questionnaire/${id}`, {
                headers: { Authorization: `Bearer ${idToken}` },
              })
            )
          )
          .then((resArr) => {
            const reducedList = questionnaireList.filter(
              (questionnaire) =>
                !resArr.some(
                  (res) =>
                    res.data.questionnaire_id === questionnaire.questionnaire_id
                )
            );
            dispatch({
              type: "DELETE_QUESTIONNAIRE_SUCCESS",
              payload: reducedList,
            });
          })
          .catch((err) => {
            console.log(err);
            dispatch({ type: "DELETE_QUESTIONNAIRE_FAILED", payload: err });
          });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "DELETE_QUESTIONNAIRE_FAILED", payload: err.message });
      });
  };
};
