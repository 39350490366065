import authReducer from "./authReducer";
import clientReducer from "./clientReducer";
import questionnaireReducer from "./questionnaireReducer";
import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";

const rootReducer = combineReducers({
  auth: authReducer,
  questionnaire: questionnaireReducer,
  client: clientReducer,
  firebase: firebaseReducer,
});

export default rootReducer;
