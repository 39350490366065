import { makeStyles, Grid } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  dataGrid: {
    height: 300,
    width: "100%",
    marginTop: 50,
  },
}));

const getRowId = (row) => row.uid;

export default function ClientList({ rows, columns, setSelectedClients }) {
  const classes = useStyles();

  return (
    <Grid item className={classes.dataGrid}>
      <DataGrid
        rows={rows}
        getRowId={getRowId}
        columns={columns}
        checkboxSelection
        onSelectionChange={(newSelection) => {
          setSelectedClients(newSelection.rowIds);
        }}
      />
    </Grid>
  );
}
